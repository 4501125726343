.highlight {
  background-color: yellow;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, .15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, .15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item {}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

@keyframes menuShow {
  from {
    opacity: 0;
  }
}

@keyframes menuHide {
  to {
    opacity: 0;
  }
}

.menu {
  font-family: sans-serif;
  font-size: 0.925rem;
  user-select: none;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 6px;
  min-width: 10rem;
}

.menuOpening {
  composes: menu;
  animation: menuShow 0.15s ease-out;
}

.menuClosing {
  composes: menu;
  animation: menuHide 0.2s ease-out forwards;
}

.menuItem {
  border-radius: 6px;
  padding: 0.375rem 0.625rem;
}

.menuItemHover {
  composes: menuItem;
  color: #fff;
  background-color: #59a2ff;
}

.submenuItem {
  position: relative;
}

.submenuItem::after {
  position: absolute;
  width: 7px;
  right: 0.625rem;
}

.menuDivider {
  margin: 0.5rem 0.625rem;
}

.badge1 {
  position: relative;
}

.badge1:after {
  content: attr(data-badge);
  position: absolute;
  top: -5px;
  left: 0px;
  font-size: .7em;
  background: orange;
  color: white;
  width: 10px;
  height: 10px;
  text-align: flex-start;
  line-height: 18px;
  box-shadow: 0 0 1px #333;
}